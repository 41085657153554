import { createRouter, createWebHistory } from 'vue-router'
import Login from '../pages/Login.vue'
import Register from '../pages/Register.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
      path: '/register',
      name: 'Register',
      component: Register,
  },
  {
    path: '/diary',
    name: 'Calendar',
    component: () => import(/* webpackChunkName: "calendar" */ '../pages/Calendar.vue'),
    meta: { navigation:true, auth: true }
  },
  {
    path: '/dishes',
    name: 'Dishes',
    component: () => import(/* webpackChunkName: "dishes" */  '../pages/Dishes.vue'),
    meta: { navigation: true, auth: true },
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import(/* webpackChunkName: "categories" */  '../pages/Categories.vue'),
    meta: { navigation: true, auth: true },
  },
  {
    path: '/feed',
    name: 'Feed',
    component: () => import(/* webpackChunkName: "feed" */  '../pages/Feed.vue'),
    meta: { navigation: true, auth: true },
  },
  {
    path: '/:username/:id',
    name: 'FeedDetail',
    component: () => import(/* webpackChunkName: "feedDetail" */  '../pages/FeedDetail.vue'),
    meta: { navigation: true, auth: true },
  },
  {
    path: '/:username',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */  '../pages/Profile.vue'),
    meta: { navigation: true },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function isLoggedIn() {
  const token = window.localStorage.getItem("token")
  return !! token;
}

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !isLoggedIn()) {
    next({path: '/'});
  } else {
    next();
  }
})

export default router
