<template>
  <div>
    <Flash v-if="showFlash" :message="flashMessage" :type="flashType"></Flash>
    <div
      class="flex flex-col h-screen items-center justify-center bg-gray-100 w-full"
    >
      <img
        class="w-3/4 md:w-1/3"
        src="@/assets/wohnzimmer-kantine-logo.png"
        alt="wohnzimmer-kantine.de Logo"
      />
      <form
        class="mt-6 w-3/4 md:w-1/4 p-6 border rounded-lg bg-white"
        @submit.prevent="login"
      >
        <div class="form-block">
          <label for="email" class="block text-sm font-medium text-gray-700"
            >Email</label
          >
          <input
            type="email"
            id="email"
            name="email"
            v-model="email"
            class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
          />
        </div>
        <div class="form-block mt-4">
          <label for="password" class="block text-sm font-medium text-gray-700"
            >Passwort</label
          >
          <input
            type="password"
            id="password"
            name="password"
            v-model="password"
            class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
          />
        </div>
        <div class="form-block mt-4">
          <button type="submit" class="w-full btn-primary">
            {{ $t("login") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import Flash from "@/components/Flash";

export default {
  name: "Login",
  components: { Flash },
  setup() {
    const email = ref("");
    const password = ref("");
    const router = useRouter();
    const showFlash = ref(false);
    const flashMessage = ref("");
    const flashType = ref("");

    const login = async () => {
      try {
        const { data } = await window.axios.post("/login", {
          email: email.value,
          password: password.value,
        });

        window.localStorage.setItem("token", data.token);
        window.localStorage.setItem("meals", JSON.stringify(data.user.meals));
        window.localStorage.setItem("user", JSON.stringify(data.user));
        router.push("/diary");
      } catch ({ response }) {
        showFlash.value = true;
        flashMessage.value = response.data.message;
        flashType.value = response.data.type;

        setTimeout(() => {
          flashMessage.value = "";
          flashType.value = "";
          showFlash.value = false;
        }, 2500);
      }
    };

    return {
      email,
      password,
      showFlash,
      flashMessage,
      flashType,
      login,
    };
  },
};
</script>
