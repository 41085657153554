<template>
  <div class="fixed bottom-0 left-0 m-6 px-6 py-3 top rounded-lg text-gray-900" :class="type === 'success' ? 'bg-green-200' : 'bg-red-200'">
    <span>{{ $t(message) }}</span>
  </div>
</template>

<script>
export default {
  name: 'Flash',
  props: ['message', 'type'],
}
</script>
