<template>
  <div>
    <Flash v-if="showFlash" :message="flashMessage" :type="flashType"></Flash>
    <div class="flex flex-col h-screen items-center justify-center">
      <img
        class="w-3/4 md:w-1/3"
        src="@/assets/wohnzimmer-kantine-logo.png"
        alt="wohnzimmer-kantine.de Logo"
      />
      <form
        class="mt-6 w-3/4 md:w-1/4 p-6 border rounded-lg bg-white"
        @submit.prevent="register"
      >
        <div class="form-block">
          <label
            for="username"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("username") }}</label
          >
          <input
            type="text"
            id="username"
            name="username"
            v-model="newUser.username"
            class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
          />
        </div>
        <div class="form-block mt-4">
          <label
            for="name"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("name") }}</label
          >
          <input
            type="text"
            id="name"
            name="name"
            v-model="newUser.name"
            class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
          />
        </div>
        <div class="form-block mt-4">
          <label for="email" class="block text-sm font-medium text-gray-700">{{
            $t("email")
          }}</label>
          <input
            type="email"
            id="email"
            name="email"
            v-model="newUser.email"
            class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
          />
        </div>
        <div class="form-block mt-4">
          <label
            for="password"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("password") }}</label
          >
          <input
            type="password"
            id="password"
            name="password"
            v-model="newUser.password"
            class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
          />
        </div>
        <div class="form-block mt-4">
          <label
            for="password_confirmation"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("password-confirm") }}</label
          >
          <input
            type="password"
            id="password_confirmation"
            name="password_confirmation"
            v-model="newUser.password_confirmation"
            class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
          />
        </div>
        <div class="form-block mt-4">
          <button type="submit" class="w-full btn-primary">
            {{ $t("register") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import Flash from "@/components/Flash";

export default {
  name: "Register",
  components: { Flash },
  setup() {
    const newUser = ref({
      username: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    });
    const router = useRouter();
    const showFlash = ref(false);
    const flashMessage = ref("");
    const flashType = ref("");

    const register = async () => {
      try {
        await window.axios.post("/register", {
          email: newUser.value.email,
          password: newUser.value.password,
          username: newUser.value.username,
          name: newUser.value.name
        });
        router.push("/");
      } catch ({ response }) {
        showFlash.value = true;
        flashMessage.value = response.data.message;
        flashType.value = response.data.type;

        setTimeout(() => {
          flashMessage.value = "";
          flashType.value = "";
          showFlash.value = false;
        }, 2500);
      }
    };

    return {
      newUser,
      showFlash,
      flashMessage,
      flashType,
      register,
    };
  },
};
</script>
