import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import mitt from 'mitt'
import App from './App.vue'
import './index.css'
import router from './router'
import de from './locales/de.json'
import en from './locales/en.json'

// Axios
window.axios = require('axios')
window.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
window.axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

// EventBus
window.emitter = mitt()

// Dayjs
window.dayjs = require('dayjs');
require('dayjs/locale/de');
let weekOfYear = require('dayjs/plugin/weekOfYear');
let weekday = require('dayjs/plugin/weekday')
var isoWeek = require('dayjs/plugin/isoWeek')
var relativeTime = require('dayjs/plugin/relativeTime')

window.dayjs.extend(weekOfYear);
window.dayjs.extend(weekday);
window.dayjs.extend(isoWeek);
window.dayjs.extend(relativeTime)

window.dayjs.locale('de');

// Locales
const languages = {
  de: de,
  en: en
}
const messages = Object.assign(languages)

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'en',
  messages
})

// App
createApp(App).use(router).use(i18n).mount('#app')
